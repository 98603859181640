<template>
  <div class="col-12" id="home">
    <video-background
        src="./video/handbrake/1-introvideo.mp4"
        preload="auto"
        :sources="[
              {src: './video/handbrake/1-introvideo-mobile.mp4', res: 768, autoplay: true}
            ]"
        transition=""
    />
    <div class="logo text-center">
      <img :src="require('@/assets/johnandmrsmith_logo.svg')" alt="John & Mr Smith logo" v-on:load="logoLoaded">
      <h1>
        John & Mr Smith
        <br />
        <span>dj saxofonist duo</span>
      </h1>
    </div>
    <div class="scroll-from-home">
      <a href="#" v-scroll-to="{ element: '#promo-video'}">
        <b-icon icon="chevron-double-down"></b-icon>
      </a>
    </div>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: "Intro",
  components: {
    VideoBackground,
  },
  mounted() {

  },
  methods: {
    logoLoaded (e) {
      let logo = e.target;
      setTimeout(function() {
        logo.classList.add('fade-in');
        setTimeout(function(){
          document.getElementsByTagName('h1')[0].classList.add('fade-in');
          setTimeout(function(){
            document.getElementsByTagName('h1')[0].getElementsByTagName('span')[0].classList.add('fade-in');
          }, 1000);
        }, 1000);
        setTimeout(function() {
          document.getElementsByClassName('vue-responsive-videobg')[0].classList.add('fade-in');
        }, 3500);
        setTimeout(function() {
          document.getElementsByClassName('open-menu')[0].classList.add('fade-in');
          document.getElementsByClassName('scroll-from-home')[0].classList.add('fade-in');
        }, 7000);
      }, 500);
    }
  }
}
</script>

<style scoped lang="scss">

$b-icon-animation-cylon-duration: 5s;

#home {
  position: relative;

  section.vue-responsive-videobg {
    height: 100vh;
    opacity: 0;
    transition: opacity 5s;

    .fade-in {
      opacity: 1;
    }
  }

  .fade-logo {
    &-enter-active,
    &-leave-active{
      transition: opacity 5s;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  @keyframes svgLogo {
    0% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(0.95);
    }
  }

  .logo {
    position: absolute;
    top: 15%;
    left: calc(50% - 50vw);
    width: 100%;
    @media (min-width: 768px) {
      left: calc(50% - 400px);
      width: 800px;
    }
    img {
      opacity: 0;
      transition: opacity 5s;
      animation-name: svgLogo;
      animation-timing-function: linear;
      animation-iteration-count:infinite;
      animation-duration: 5s;
      width: 140px;
      @media (min-width: 768px) {
        width: 23vw;
      }
    }
    h1 {
      opacity: 0;
      transition: opacity 5s;
      margin-top: 15px;
      font-weight: 300;
      font-size: 23px;
      letter-spacing: 5.3px;
      @media (min-width: 768px) {
        margin-top: 60px;
        font-size: 74px;
        letter-spacing: 13px;
        line-height: 60px;
      }
      span {
        opacity: 0;
        transition: opacity 3s;
        letter-spacing: 2.5px;
        font-size: 15px;
        font-weight: 100;
        line-height: 45px;
        @media (min-width: 768px) {
          font-size: 30px;
          letter-spacing: 5px;
        }
      }
    }
  }

  @keyframes scrollFromHome {
    0% {
      bottom: 0;
    }
    50% {
      bottom: -20px;
    }
    100% {
      bottom: 0;
    }
  }
  .scroll-from-home {
    opacity: 0;
    transition: opacity 5s;
    position: absolute;
    animation-name: scrollFromHome;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 50;
    font-size: 300%;
    > a {
      color: #FFF;
    }
  }
}
</style>