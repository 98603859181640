<template>
  <nav>
    <button class="open-menu position-fixed" v-on:click="openMenu" v-bind:class="{ 'd-none' : menuIsOpen}">
      <b-icon icon="list" font-scale="3" style="color: #FFF;"></b-icon>
    </button>
    <div class="menu position-fixed" v-bind:class="{ open: menuIsOpen }" ref="menu">
      <button class="close-menu" v-on:click="openMenu">
        <b-icon icon="x" font-scale="4" style="color: #FFF;"></b-icon>
      </button>
      <ul class="list-unstyled">
        <li><a href="#home" v-on:click="openMenu" v-scroll-to="{ element: '#home'}" title="Home">Home</a></li>
        <li><a href="#promo-video" v-on:click="openMenu" v-scroll-to="{ element: '#promo-video'}" title="Promo video">Promo video</a></li>
        <li><a href="#muziek-en-uitvoering" v-on:click="openMenu" v-scroll-to="{ element: '#muziek-en-uitvoering'}" title="Muziek & Uitvoering">Muziek & Uitvoering</a></li>
        <li><a href="#personen-en-achtergrond" v-on:click="openMenu" v-scroll-to="{ element: '#personen-en-achtergrond'}" title="Personen & Achtergrond">Personen & Achtergrond</a></li>
        <li><a href="#storytelling-en-setup" v-on:click="openMenu" v-scroll-to="{ element: '#storytelling-en-setup'}" title="Storystelling & Setup">Storystelling & Setup</a></li>
        <li><a href="#werkwijze-en-prijzen" v-on:click="openMenu" v-scroll-to="{ element: '#werkwijze-en-prijzen'}" title="Werkwijze & Prijzen">Werkwijze & Prijzen</a></li>
        <li><a href="#contact" v-on:click="openMenu" v-scroll-to="{ element: '#contact'}" title="Contact">Contact</a></li>
      </ul>
      <h3 class="text-center">Boek direct</h3>
      <iframe src="https://limitlessdjs.nl/johnandmrsmith/" frameborder="0" style="overflow:hidden; width: 100%; height: 537px;"></iframe>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      menuIsOpen: false
    }
  },
  methods: {
    openMenu: function (e) {
      this.menuIsOpen = this.menuIsOpen === false;
      // Set opacity to 1 when the fade-in class is detected
      let menuButton = document.querySelector('nav > button.open-menu');
      if (menuButton.classList.contains('fade-in')) {
        menuButton.style.opacity = 1;
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>