<template>
  <div class="col-12 embed-responsive" id="contact">
    <iframe src="https://limitlessdjs.nl/johnandmrsmith/" class="embed-responsive-item" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped lang="scss">
  #contact {
    height: 100vh;
    iframe {
      height: 537px;
    }
  }
</style>