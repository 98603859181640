<template>
  <div class="video-wrapper">
    <button class="video-control-button" v-bind:class="[{ pause: isPause }, { 'fade-out': fadeOut}]" v-on:click="toggleVideo" :data-target="target"></button>
    <video-background
      :src="src"
      :poster="poster"
      preload="none"
      v-bind:autoplay="true"
      :ref="target"
      :muted="false"
      playsWhen="canplaythrough"
      @ready="videoIsReady"
      @playing="videoIsPlaying"
      @loading="videoIsLoading"
    />
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: "PlayButton",
  components: {
    VideoBackground,
  },
  props: {
    target: String,
    src: String,
    poster: String
  },
  data() {
    return {
      isPause: true,
      fadeOut: false
    }
  },
  mounted() {

  },
  methods: {
    videoIsReady() {
      // Video is ready event
    },
    videoIsPlaying() {
      // Video is playing event
    },
    videoIsLoading() {
      // Video is loading event
    },
    toggleVideo(e) {
      this.isPause = this.isPause === false;
      this.fadeOut = this.fadeOut === false;
      if (this.isPause === false) {
        // Check if there is any video already playing.
        // Get videoPlaying property of parent element
        if (this.$parent.videoPlaying !== null) {
          // Get component with playing video and pause video
          this.$parent.$refs[this.$parent.videoPlaying].$refs[this.$parent.videoPlaying].player.pause();
          this.$parent.$refs[this.$parent.videoPlaying].isPause = true;
          this.$parent.$refs[this.$parent.videoPlaying].fadeOut = false;
        }
        let video = this.$refs[this.target].$el.children[1].children[0];
        //video.setAttribute('src', this.src);
        let isMobile = ('ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent));
        if (isMobile === true) {
          // Start playing video on fullscreen on mobile devices
          video.removeAttribute('playsinline');
          video.play();
          if(video.requestFullScreen){
            video.requestFullScreen();
          } else if(video.webkitRequestFullScreen){
            video.webkitRequestFullScreen();
          } else if(video.mozRequestFullScreen){
            video.mozRequestFullScreen();
          }
        } else {
          video.setAttribute('disablePictureInPicture', true);
          video.disablePictureInPicture = true;
          // Start playing video on desktop
          this.$refs[this.target].player.play();
        }
        this.$emit('setVideoPlaying', this.target)
      } else {
        // Pause video
        this.$refs[this.target].player.pause();
        this.$emit('setVideoPlaying', null)
        this.fadeOut = false;
      }
    }
  }
}
</script>

<style lang="scss">
  $custom-cube-bezier-animation: cubic-bezier(.5,-0.2,.5,1.2);
  $custom-cube-bezier-animation-1: cubic-bezier(.75,0,.25,1);

  .video-with-content {
    width:200vw;
    .video-wrapper {
      position: relative;
      display: inline-block;
      video {
        /* This css is only applied when fullscreen is active. */
        &:fullscreen {
          max-width: none;
          width: 100%;
        }
        &:-webkit-full-screen {
          max-width: none;
          width: 100%;
        }
      }
    }
    .video-control-button {
      border: 0;
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      transition: left 1s $custom-cube-bezier-animation-1;
      z-index: 49;
      height: 100px;
      width: 100px;
      background-image: url('~@/assets/play-circle-fill.svg');
      background-image: url('~@/assets/pause-circle-fill.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      transition-property: all;
      filter: drop-shadow(0 0 30px rgba(0,0,0,0.5));
      &.pause {
        background-image: url('~@/assets/play-circle-fill.svg');
      }
      &.fade-out {
        transition-delay: 0.2s;
        transition-duration: 0.6s;
        transition-timing-function: ease-in-out;
        opacity: 0.25;
        transform: scale(0.7);
        &:hover {
          transform: scale(0.85);
        }
      }
      &:hover {
        transition-delay: 0.1s;
        transition-duration: 0.2s;
        opacity: 1;
      }
    }
    &.open {
      .video-control-button {
        @media (max-width: 768px) {
          left: -100vw;
        }
      }
    }
    section.vue-responsive-videobg {
      height: 100vh;
      width: 100vw;
      display: inline-block;
      transition: width 1s $custom-cube-bezier-animation-1;
    }
    div.content {
      width: 100vw;
      display: inline-block;
      vertical-align: top;
      padding: 10px 30px;
      @media (min-width: 768px) {
        width: 50vw;
      }

      h2 {
        font-size: 2.5rem;
      }

      .tabs {
        margin-top: 15px;
        position: relative;
        .tab-content {
          height: calc(100vh - 170px);
          overflow: auto;
          scrollbar-width: thin;          /* "auto" or "thin" */
          scrollbar-color: #FFF black;   /* scroll thumb and track */
          p.lead, p:first-child {
            padding-top: 15px;
          }
        }
      }
    }
    &.open {
      @media (min-width: 768px) {
        width: 150vw;
      }
      section.vue-responsive-videobg {
        width: 0;
        @media (min-width: 768px) {
          width: 50vw;
        }
      }
      div.content {
        @media (min-width: 768px) {
          width: 50vw;
        }
      }
    }
  }

</style>