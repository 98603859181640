<template>
  <div class="container-fluid">
    <div class="row">
      <Intro></Intro>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh; overflow:hidden;" id="promo-video">
        <div class="video-with-content" v-bind:class="{ open: isOpen }">
          <PlayButton src="./video/handbrake/2-promovideo.mp4" poster="./img/2_promovideo-compressed.jpg" target="promoVideoMedia" v-on:setVideoPlaying="setVideoPlaying" ref="promoVideoMedia" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh; overflow:hidden;" id="muziek-en-uitvoering">
        <div class="video-with-content" v-bind:class="{ open: isOpen }">
          <PlayButton src="./video/handbrake/3-uitvoering-en-presentatie.mp4" poster="./img/3_muziek_uitvoering-compressed.jpg" target="muziekEnUitvoering" v-on:setVideoPlaying="setVideoPlaying" ref="muziekEnUitvoering" />
          <div class="content">
            <h2 class="text-center">Muziek & Uitvoering</h2>
            <b-tabs justified>
              <b-tab title="Primair: Muziek" active>
                <p class="lead">
                  Wij zijn John & Mr Smith.
                </p>
                <p>
                  Wat John & Mr Smith onderscheid is dat zij een vast duo zijn met jarenlange ervaring. Samen speelden zij meerwekelijks in binnen en buitenland, op kleine en grote feesten en hebben daardoor een samenspel wat volledig op elkaar is ingesleten.
                </p>
                <p>
                  Door de saxofonist toe te voegen, botsen de twee werelden van live muziek en strak afgeproduceerde muziek en ontstaat er een energieke hybride, met de toepasbaarheid van een DJ en de energie van een live act. Door hun velbesteden voelen ze elkaar feilloos aan.
                </p>
                <p>
                  John & Mr Smith houden van muziek van artiesten als Bakermat, Klingande, Goldfish en Sam Feldt. Dit zijn grotere dance saxofonist acts. We kijken af bij hen en proberen er een eigen iets van te maken.<br />
                  Door John & Mr Smiths ervaring en doordat ze veel tijd en aandacht in het doorontwikkelen van hun act besteden, weten ze hun act tot een ongekend niveau te brengen.
                </p>
                <h3 class="text-center">Muziek</h3>
                <p>
                  De saxofonist voegt muzikaal energie toe aan de muziek. Door op de juiste momenten te spelen jut hij het publiek op als een muzikale MC. De saxofonist speelt niet non-stop, maar voelt ook aan wanneer hij moet spelen.Hij geeft aan waar de muziek heen gaat en zet aan waar nodig.
                </p>
                <p>
                  De saxofoon is een prachtig instrument wat klank betreft. Van subtiel dwarrelende melodieen tot keihard scheuren, met een saxofoon heb je een heel brede range aan expressie.
                </p>
                <p>
                  De saxofonist speelt op 2 manieren mee: met nummers waar hij de melodie van meespeelt en door improvisatie. Door de vele jaren ervaring kan saxofonist Chris de nummers perfect meespelen. Hij improviseert door aanvullende lijnen te spelen, en zijn eigen jazz riffs toe te voegen. Dit maakt de saxofonist breed inzetbaar, qua muziek, setting en gelegenheid. Geen optreden is hetzelfde, dus is het essentieel om als muzikant in te spelen op de gasten, om ze erbij te betrekken.
                  Doordat ze veel nummers tot in de puntjes hebben geoefend, zijn John & Mr Smith experts in hun muziekstijl.
                </p>
                <h3 class="text-center">DJ</h3>
                <p>
                  De voordelen van een DJ zit in de diversiteit en het schakelen in de muziekstijlen en de energie. Met het mixwerk van een DJ voegt variatie en energie toe aan de muziek. Ook een voordeel is dat de DJ nonstop draait.
                </p>
                <p>
                  Door de juiste nummers achter elkaar te spelen, zet de DJ een lijn uit, met als doel om de energie te bouwen. Natuurlijk is het elke keer weer belangrijk om het juiste nummer te kiezen voor het moment, maar door de juiste nummers achtereenvolgens te kiezen kan de energie zich opstapelen, en dat voel je op de dansvloer.
                </p>
                <p>
                  DJ John ondersteund de muziek en de saxofonist met zijn pianospel. Piano is de perfecte aanvulling op de saxofoon. Veel DJ saxofonist nummers combineren piano met saxofoon. Na jaren met saxofonist te hebben gedraaid, voegt John nu zelf ook muzikaal iets toe te voegen aan de act.
                </p>
                <p>
                  De DJ kan makkelijk inspelen op het publiek door de muziekstijl en energie aan te passen aan de sfeer. Het is essentieel om als DJ aan te voelen wat het publiek wil en wat het nodig heeft om te komen waar de avond heen moet.
                </p>
                <p>
                  Van de nummers die John & Mr Smith vaak spelen maken zij eigen versies die het meest geschikt zijn om ze live te performen. Deze nummers hebben vaak hogere energie en waar sneller tot de clue van de muziek te komen, waardoor de aandacht van het publiek vast wordt gehouden.
                </p>
              </b-tab>
              <b-tab title="Secundair: Vorm">
                <p>
                  Na jarenlang te hebben gespeeld de muziek als essentie, hebben John & Mr Smith geleerd dat ook de vorm belangrijk is. De boodschap blijft het belangrijkste, maar hoe je het vertelt net zo belangrijk.
                </p>
                <h3 class="text-center">Kleding & Setup</h3>
                <p>
                  De nieuwe setup van John & Mr Smith hebben ze ontworpen met openheid en transparatie in gedachten: we zijn trots op wie we zijn en wat we doen, en dat willen we ook graag laten zien. En we houden van compact en efficient, zonder fratsen. Doordat je door de nieuwe opstelling heen kunt kijken, zijn wij meer zichtbaar. Er zit veel slimme techniek in de opstelling en tegelijk is hij heel compact en laten we precies zien wat we doen.
                </p>
                <p>
                  Wat betreft kleding kiezen John & Mr Smith conservatief, net en neutraal. Het idee daarbij is: het is makkelijker underdressed te zijn dan overdressed. Hun eigen expressie komt naar voren in hun schoenen.
                </p>
                <h3 class="text-center">Lichten & Effecten</h3>
                <p>
                  Belangrijk voor John & Mr Smith is dat de lichten de muziek ondersteunen. De lichten leiden geen eigen leven, maar vertellen hetzelfde als de muziek.
                </p>
                <p>
                  De belichting is inmiddels een essentieeel onderdeel geworden van de act. Door de belichting zelf aan te sturen en voor te programmeren, creeren John & Mr Smith de sfeer en energie die ze voor ogen hebben.
                </p>
                <p>
                  Het doel van de lichten is om aan te zetten tot dansen. Met intensiteit, het ritme van de muziek en qlymaxen te weerspiegelen in de lichten, voel je als publiek intuitief wat te doen. Dat maakt het makkelijk voor het publiek, en kunnen zij zich verder bezighouden met waar het om draait; feesten, dansen en lol maken met elkaar.
                </p>
                <p>
                  De kleuren van de lichten zijn gekozen om de sfeer van elk nummer te ondersteunen. John & Mr Smith kiezen hier vaak voor basiskleuren. Het idee hierbij is dat met de toevoeging van de saxofonist, de toetsen en de beelden er al zoveel meer gebeurd, dat complexe kleurencombinaties onoverzichtelijk zouden maken.
                </p>
                <p>
                  De kleuren en ritmes van de lichten worden voor de veelgespeelde nummers door John & Mr. Smith van te voren geprogrammerd, maar alles kan ter plekke nog worden bijgestuurd door de manier waarop zij het systeem voor hun act hebben ontwikkeld.
                </p>
                <p>
                  De lichten zijn aanwezig, maar voornamelijk ter ondersteuning. De lichten geven aan wat de muziek op dat moment doet en maakt het gemakkelijker om te begrijpen wat er gebeurd en waar de muziek heen gaat.
                </p>
                <p>
                  Als John & Mr Smith bijvoorbeeld nieuwe muziek spelen kan het publiek door de belichting nog steeds begrijpen waar de muziek naartoe gaat. Als het makkelijk is, maakt het het ook leuker.
                </p>
                <p>
                  John & Mr Smith gebruiken rook en de vonkenregen als effecten. Ze zijn de kers op de taart voor de act en laten de opgebouwde energie exploderen op de meest energieke momenten.<br />
                  Rook maakt de sfeer mysterieuzer en zorgt ervoor dat de lichtstralen mooi uitkomen. De vonkenmachines zijn vergelijkbaar met sterretjes, je kunt een blaadje papier boven de vonkenregen houden en het blaadje zal niet ontbranden.
                </p>
                <h3 class="text-center">Visuals</h3>
                <p>
                  De visuals zijn een toevoeging aan de act welke John & Mr Smith tot iets unieks maakt. Geen andere act integreert videobeelden en animaties op zo'n manier als dat John & Mr Smith dat doen.
                </p>
                <p>
                  De beelden zijn net als de lichten ter ondersteuning van de muziek en sfeer. Na jaren met alleen licht en geluid te hebben gewerkt, wilden John & Mr Smith iets toevoegen aan de act om mensen nog meer bij de act en muziek te betrekken. Door de songteksten, ritmische beelden en sfeervolle beelden te kiezen komt de muziek nog meer tot leven.
                </p>
                <p>
                  Er zit vaak een boodschap in de nummers die John & Mr Smith spelen. Met de songteksten proberen ze deze boodschap naar voren te brengen. Door de songteksten op het scherm zichtbaar te maken, komt de bedoeling van het nummer nog meer naar voren en kan de bedoeling van het nummer blootgelegd worden.
                </p>
                <p>
                  De beelden geven ook aan wat te doen; als de muziek opbouwt komen er heftiger wordende vormen op de schermen en bij qlymaxen worden gebeuren de meest intensieve beelden en geven aan dat het tijd is om te feesten!
                </p>
                <p>
                  Een beeld zegt meer dan 1000 woorden. De landschappen en omgevingen van de beelden worden zo gekozen dat ze de boodschap van het nummer ondersteunen. John & Mr Smith proberen met deze boodschappen een verhaal te vertellen.
                </p>
                <p>
                  De beelden zijn één met de lichten doordat ze dezelfde bewegingen maken en kleuren hebben. Dat zorgt voor eenheid en dat het makkelijk te begrijpen is.
                </p>
                <p>
                  Het John & Mr Smith logo staat centraal in de videos. Voor gelegenheden zijn daar custom logo's of gelegenheidssymbolen voor mogelijk, waardoor de beelden ook specifiek voor de gelegenheid worden.
                </p>
                <p>
                  Net als met de lichten zijn de beelden gemaakt om mensen aan te zetten tot feesten. Ze zijn gemaakt om makkelijk te begrijpen, meegenomen kunnen worden in de muziek. Maar doordat het makkelijk te begrijpen is, kan er ook gewoon genoten worden van de sfeer en het feest.
                </p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh; overflow:hidden" id="personen-en-achtergrond">
        <div class="video-with-content" v-bind:class="{ open: isOpen }">
          <PlayButton src="./video/handbrake/3-promovideo.mp4" poster="./img/4_personen_achtergrond-compressed.jpg" target="personenEnAchtergrond" v-on:setVideoPlaying="setVideoPlaying" ref="personenEnAchtergrond" />
          <div class="content">
            <h2 class="text-center">Personen & achtergrond</h2>
            <b-tabs justified>
              <b-tab title="Duo" active>
                <p>
                  John & Mr Smith is een vast DJ & Saxofonist duo welke optreed in Nederland en Belgie, maar ook Duitsland, Frankrijk, Italie en Spanje. John & Mr Smith spelen op feesten groot en klein, voor verschillende settings. In de loop der jaren hebben ze hun DJ & saxofonist act uitgebouwd: zo zijn er visuals en effecten toegevoegd en speelt John nu ook toetsen. Hun muziek, performance, de lichten en beelden zijn geoptimaliseerd voor hun act. Muziek blijft de kern vormen voor hun optredens: alles wat we extra doen is om de muziek sterker, beter of krachtiger over te laten komen. Het doel is om alle zintuigen te prikkelen en het publiek los te laten gaan op de muziek. Ze laten graag zien wat ze doen, en de techniek is daarbij een middel om hun act te laten schitteren.
                </p>
              </b-tab>
              <b-tab title="Chris">
                <p>
                  Chris (1991) is vanaf kleins af aan met muziek bezig geweest. Dat begon met de HAVO voor muziek en dans, waarna hij doorstudeerde op het Conversatorium HKU in Utrecht. Daarnaast geeft Chris les als saxofoonleraar, speelt in een energieke brassband en heeft een eigen blazerssectie. Hij is fulltime met muziek bezig.
                </p>
                <p>
                  Chris is in eerste instantie bij John & Mr Smith gekomen als invaller. Meteen was er een klik en zag Joost Chris zijn grote potentie: dat is zijn, onmiskenbare Wall of Sound. Chris heeft voor zijn sound een heel duidelijke visie en door jarenlange finetuning weet hij die overweldigende klank te realiseren. Dat gecombineerd met toewijding en inzet heeft hem tot de vaste saxofonist gemaakt van John & Mr. Smith. Inmiddels spelen Joost en Chris 5 jaar als duo samen en hebben ze in alle windstreken van Nederland en België gespeeld, net als een groot deel van Europa.
                </p>
                <p>
                  <strong>Wat wil jij met John & Mr Smith bereiken?</strong> <br />
                  Mijn doel is om muziek te maken voor publiek en mijn passie voor muziek te delen met zoveel mogelijk mensen.
                </p>
                <p>
                  <strong>Wat is je drive voor John & Mr Smith?</strong> <br />
                  Ik wil mensen een ervaring geven die ze zich blijven herinneren. De kracht van John & Mr Smith is niet alleen de muziek, maar de totale ervaring die we de mensen geven. Door eigen visuals te maken en een goede lichtshow.
                </p>
                <p>
                  <strong>Meest unieke John & Mr Smith moment:</strong> <br />
                  Wij zijn twee jaar geleden mee op wintersport geweest met een Utrechtse studentenvereniging naar Zuid-Frankrijk. We moesten alle spullen met sneeuwscooters de berg op rijden. We speelden toen tijdens een rose borrel op de piste met een adembenemend uitzicht. De dames vonden het optreden zo goed en gezellig dat we nog een tweede keer hebben optreden die week. Het was een week om nooit te vergeten.
                </p>
                <p>
                  <strong>Wat vind jij van Joost?</strong> <br />
                  Ik heb Joost 5 jaar geleden leren kennen tijdens een optreden toen ik moest invallen voor een andere saxofonist. Ik merkte toen gelijk dat er een muzikale klik was. De nummerkeuze was goed, maar vooral zijn manier van draaien is echt te gek. Ik heb met veel DJ's gespeeld, maar niemand draait zoals Joost.
                  <br />
                  Joost is een harde werker, een doorzetter. Hij werkt uren en uren aan het concept John & Mr Smith. Hij werkt aan de visuals, het geluid, hij maakt van elk nummer een edit. Ik hoor Joost vaak zeggen: 'Het moet toch simpeler en beter kunnen', en dan zorgt hij daar ook voor.
                  Hij heeft de goede mindset om John & Mr. Smith groter te maken en te zorgen dat we zoveel mogelijk mensen kunnen bereiken met onze muziek.
                </p>
                <p>
                  <strong>Waar zie je John & Mr Smith in de toekomst?</strong> <br />
                  Ik zou met met John & Mr. Smith graag de grote podia willen betreden. Grote concerten/festivals in binnen en buitenland. Onze eigen nummers, onze passie en voorliefde voor muziek met zoveel mogelijk mensen delen.
                </p>
              </b-tab>
              <b-tab title="Joost">
                <p>
                  Joost (1986) is van jongs af aan met muziek bezig geweest. Eerst met piano spelen, later ook produceren en draaien. Dat doet hij inmiddels al 25 jaar, waarvan 10 jaar professioneel. Na het afmaken van de universitaire studie Werktuigbouwkunde in Eindhoven, wilde Joost zijn passie achterna: de muziek. Hij heeft een agency voor acts van DJs in combinatie met live artiesten. Onder andere door de vraag naar John & Mr Smith en door optredens van DJ met andere live instrumenten heeft hij daar nu inmiddels een goed draaiend bedrijf. Joost is daarnaast ook muziekproducent, hij ontwerpt de visuals en hij bedenkt en realiseert de techniek voor John & Mr Smith.
                </p>
                <p>
                  <strong>Wat wil je met John & Mr Smith bereiken?</strong> <br />
                  Mijn doel is om met elk optreden van John & Mr Smith mensen een unieke ervaring te geven.
                </p>
                <p>
                  <strong>Wat is je drive voor John & Mr Smith?</strong> <br />
                  Ik hou zelf van dansen en elektronische muziek. Ik wilde daarom een act creëren die alle facetten die ik tof vind combineert om zo een unieke, spectaculaire ervaring te geven. Persoonlijk haal ik allerlei dingen uit muziek en optreden: lol, verdieping, liefde, energie en van alles te meer. Ik kan me met John & Mr Smith uitdrukken uitdrukken en ik hoop dat ook aan mensen mee te geven.
                </p>
                <p>
                  <strong>Wat is je drive voor John & Mr Smith?</strong> <br />
                  Ik hou zelf van dansen en elektronische muziek. Ik wilde daarom een act creëren die alle facetten die ik tof vind combineert om zo een unieke, spectaculaire ervaring te geven. Persoonlijk haal ik allerlei dingen uit muziek en optreden: lol, verdieping, liefde, energie en van alles te meer. Ik kan me met John & Mr Smith uitdrukken uitdrukken en ik hoop dat ook aan mensen mee te geven.
                </p>
                <p>
                  <strong>Wat vind jij van Chris?</strong> <br />
                  Chris is ontzettend getalenteerd en zijn klank is overweldigend. Ik heb met heel veel verschillende saxofonisten samen gespeeld, maar Chris zijn geluid is de krachtigste. Hij is de enige die over de qlymax van een dance plaat kan spelen en niet in het niet valt. Daardoor kan hij zelfs aan de meest heftige platen nog energie toevoegen, en hoef ik me als DJ niet in te houden. Zo kunnen we als DJ & Saxofonist het hoogste energieniveau bereiken. Het is overigens niet zo dat we alleen heftig spelen. We hebben ook onze rustige, relaxte DJ saxofonist sets.
                </p>
                <p>
                  <strong>Waar zie je John & Mr Smith in de toekomst?</strong> <br />
                  Liefst zouden we met John & Mr Smith grotere publieken willen bereiken. Daarnaast vind ik het ook belangrijk dat wat we vertellen goed overkomt en dat we überhaupt mensen bereiken met onze boodschap. Ik geloof wel in onze onze act en ik denk dat we meer mensen onze unieke ervaring kunnen geven.
                </p>
                <p>
                  <strong>Meest unieke John & Mr Smith moment:</strong> <br />
                  Dat was een strand optreden voor de vrouwen van een studentenvereniging. Een kort optreden, waar we heel rustig, ontspannen begonnen, waar de dames bij de beachclub gezellig zaten te kletsen. Door de muziek op te bouwen, wisten we de sfeer binnen no-time tot een grandioos feest om te zetten. De dames hadden er ontzettend veel zin in, zongen mee, en stonden op de tafels te dansen. En wij waren de enige twee mannen! Een heel bijzonder optreden! :)
                </p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh; overflow:hidden;" id="storytelling-en-setup">
        <div class="video-with-content" v-bind:class="{ open: isOpen }">
          <div class="video-wrapper">
            <PlayButton target="storytelling" src="./video/handbrake/4-visuals.mp4" poster="./img/5_storytelling_setup-compressed.jpg" v-on:setVideoPlaying="setVideoPlaying" ref="storytelling" />
          </div>
          <div class="content">
            <h2 class="text-center">Storytelling & Setup</h2>
            <b-tabs justified>
              <b-tab title="Storytelling" active>
                <p>
                  John & Mr Smith proberen met een optreden een verhaal te vertellen. Dat doen ze door nummers met een bepaalde boodschap achtereenvolgens te spelen. De nummers vertellen elk een stap en samengenomen vertellen ze een verhaal.
                </p>
                <p>
                  Deze manier van spelen werkt versterkend op twee manieren. <br />
                  Ten eerste staan de nummers  niet langer op zichzelf. Ze maken deel van een geheel. Door de nummers zorgvuldig te seelcteren voelt hun plaats in de set veel logischer.
                  Ten tweede is er een overkoepelende betekenis. De nummers samen vertellen een boodschap, en elk nummer draagt daar aan bij. Dat maakt de set zelf ook veel krachtiger.
                </p>
                <p>
                  Voorbeelden van onze persoonlijke verhalen die we zo hebben verteld zijn:
                </p>
                <ul>
                  <li>Twee personen die elkaar voor het eerst ontmoeten en liefde ontbloeit, met alle onzekerheden en spannende momenten die ermee gepaard gaan.</li>
                  <li>Een relatie die goed begon maar waar de liefde uit verdwenen was, waarbij de conclusie was dat afscheid nemen van iemand ook houden van kan betekenen.</li>
                  <li>Zelfontwikkeling: voorgesteld als een eeuwigdurend proces, waarbij de ontwikkeling van het verslaan van je eigen tekortkomingen is.</li>
                  <li>Reactie op 2020, door doorzetten en jezelf opnieuw uitvinden, wat je niet alleen hoeft te doen.</li>
                  <li>Een 4 uur lange mix met alle facetten van liefde, vertelt in een epische verhaal met allerlei struggles.</li>
                </ul>
                <p>
                  Deze voorbereiding treffen ze vaak voor korte optredens. Ze vinden het daarbij belangrijk dat er een goede stroom energie en een duidelijke lijn door de set is. Dat geeft een extra uitdaging en levert een sterker geheel op. Bij langere optredens is inspelen op het publiek het belangrijkste en gebruiken we stukken van storytelling sets.
                </p>
                <p>
                  Het hoofddoel van een optreden blijft entertainment, het verhaal is een hulpmiddel om dat beter doen. Ook al weet het publiek niet dat er op een bepaalde manier extra aandacht is besteed aan een optreden, het merkt het wel. John & Mr Smith heeft in ieder geval gemerkt dat mensen ervaren dat er iets speciaals is met de set. Dat komt specifiek naar voren in bijvoorbeeld het einde van de set, waar het verhaal tot een conclusie komt, waarbij de boodschap vaak het sterktste is. Wat John & Mr Smith betreft geeft storytelling een hele nieuwe dimensie aan hun act.
                </p>
              </b-tab>
              <b-tab title="Techniek">
                <p>
                  Het technisch ontwerp van de setup was een enorme uitdaging. John & Mr Smith zoeken altijd de grenzen op van wat mogelijk is en pionieren graag hun eigen weg. Er worden bijna continu nieuwe elementen toegevoegd en uitgeprobeerd, waarbij alleen de echte toevoegingen aan de act blijven.
                </p>
                <p>
                  John & Mr Smith proberen hun boodschap behapbaar te houden voor het publiek. Zodat zij zich ook nog kunnen richten op genieten van de muziek en plezier maken met elkaar. John & Mr Smith dragen daar aan bij door van alles te laten gebeuren waardoor mensen meer in de muziek op kunnen gaan. Door de songteksten te laten zien met de juiste beelden en gepaste kleuren van de lichten te kiezen ontstaat er een geheel. Met de lichten en beelden bouwen ze energie en kunnen ze die weer laten losbarsten op de juiste momenten. Een optreden moet voor het publiek aanvoelen als een warm bad.
                </p>
                <p>
                  De werking van de setup lijkt verder: de DJ draait vanaf de DJ setup en de saxofonist speelt erop mee. We gebruiken de effecten van de Pioneer DJ mixer voor de saxofonist. Wee  houden daarbij van een organische galm echo, die heel divers gebruikt worden, van subtiel (long days) tot heftig (losing it).
                </p>
                <p>
                  Nummers waarbij de DJ geen piano speelt, bedient hij de beelden en lichten. Bij nummers waar de DJ piano speelt worden de beelden en lichten voorprogrammeerd. De lichten en muziek blijven wel live aanpasbaar, zodat ook bij andere tempos de beelden en lichten synchroon lopen. Voor een heel grote database aan veelgespeelde nummers hebben John & Mr Smith deze nummers voorgeprogrammeerd. Dat zorgt voor een uniek perfect geintegreerde manier van live performen John & Mr Smith Show!
                </p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh; overflow:hidden;" id="werkwijze-en-prijzen">
        <div class="video-with-content" v-bind:class="{ open: isOpen }">
          <PlayButton target="werkwijzePrijzen" src="./video/handbrake/5-promovideo.mp4" poster="./img/6_werkwijze_producten_prijzen-compressed.jpg" v-on:setVideoPlaying="setVideoPlaying" ref="werkwijzePrijzen" />
          <div class="content">
            <h2>Werkwijze & Prijzen</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Contact></Contact>
    </div>
    <div class="row">
      <div class="text-center col-12" id="footer">
        <p>
          <a href="https://johnandmrsmith.com/">John & Mr. Smith</a>
        </p>
        <ul class="list-inline">
          <li class="list-inline-item">
            <a href="http://www.facebook.com/johnandmrsmith" target="_blank">Facebook</a>
          </li>
          <li class="list-inline-item">
            <a href="http://www.twitter.com/johnandmrsmith" target="_blank">Twitter</a>
          </li>
          <li class="list-inline-item">
            <a href="http://www.soundcloud.com/johnandmrsmith" target="_blank">Soundcloud</a>
          </li>
          <li class="list-inline-item">
            <a href="http://www.youtube.com/johnandmrsmith" target="_blank">Youtube</a>
          </li>
        </ul>
        <p>
          Joost van Hooijdonk Music<br />
          Joost van Hooijdonk<br />
          <a href="http://www.joostvanhooijdonk.com/" target="_blank">www.joostvanhooijdonk.com</a><br />
          KvK: 55659438<br />
          <a href="https://johandmrsmith.com/algemene-voorwaarden">ALGEMENE VOORWAARDEN</a> | <a href="./rider.pdf">RIDER</a>
        </p>
      </div>
    </div>
    <Navigation></Navigation>
    <button class="tekstversie" v-on:click="toggleOpen">{{ text }}</button>
  </div>
</template>

<script>
import Intro from './components/Intro'
import PlayButton from "./components/PlayButton";
import Contact from "./components/Contact";
import Navigation from "./components/Navigation";

export default {
  name: 'App',
  components: {
    Intro,
    PlayButton,
    Contact,
    Navigation
  },
  data() {
    return {
      isOpen: false,
      text: "tekstversie",
      videoPlaying: null
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleOpen: function (e) {
      this.isOpen = this.isOpen === false;
      if (this.isOpen === true) {
        this.text = "videoversie";
      } else {
        this.text = "tekstversie";
      }
    },
    handleScroll: function (e) {
      // Show or hide tekstversie button
      if (Math.round(window.pageYOffset) >= window.innerHeight) {
        document.getElementsByClassName('tekstversie')[0].classList.add('show');
      } else {
        document.getElementsByClassName('tekstversie')[0].classList.remove('show')
      }
    },
    setVideoPlaying: function(video) {
      this.videoPlaying = video;
    }
  }
}
</script>

<style lang="scss">
  $bg-color: #181818;
  $text-color: #E8E8E8;
  $border-color: #E8E8E8;
  $custom-cube-bezier-animation: cubic-bezier(.5,-0.2,.5,1.2);
  $custom-cube-bezier-animation-1: cubic-bezier(.75,0,.25,1);

  * {
    scroll-behavior: smooth;
  }
  body {
    background: $bg-color;
    color: $text-color;
    font-family: 'Roboto', sans-serif;
  }
  .fade-in {
     opacity: 1 !important;
  }
  .tekstversie {
    z-index: 50;
    position: fixed;
    bottom: -60px;
    transition: bottom .65s $custom-cube-bezier-animation-1;
    right: 30px;
    color: $text-color;
    width: 125px;
    height: 44px;
    border: 2px solid $border-color;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 24px;
    &.show {
      position: fixed;
      bottom: 30px;
    }
  }
  button.open-menu {
    opacity: 0;
    transition: opacity 5s;
    top: 30px;
    left: 30px;
    border: 0;
    background: none;
    color: white;
    padding: 0;
    z-index: 51;
    @media (max-width: 768px) {
      svg {
        filter: drop-shadow(0 0 3px #000) drop-shadow(0 0 6px #000) drop-shadow(0 0 10px #000);
      }
    }
  }
  div.menu {
    top: 0;
    left: -100vw;
    transition: left 1.3s $custom-cube-bezier-animation-1;
    width: 100vw;
    @media (min-width: 768px) {
      left: -480px;
      width: 480px;
    }
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    @media (min-width: 768px) {
      background: rgba(0, 0, 0, .9);
    }
    z-index: 51;

    &.open {
      left: 0;
      overflow-y: auto;
    }
    .close-menu {
      position: absolute;
      top: 20px;
      right: 20px;
      border: 0;
      background: none;
      color: #FFF;
    }
    ul {
      margin: 80px 30px;
      @media (min-width: 768px) {
        margin: 60px 30px;
      }
      > li {
        font-size: 24px;
        line-height: 46px;
        @media (min-width: 768px) {
          font-size: 38px;
          line-height: 78px;
        }
        a {
          color: #FFF;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .col-12 {
    padding: 0;
    position: relative;
  }
  div#footer {
    a {
      color: #FFF;
      text-decoration: underline;
    }
  }

  .vimeo {
    background: black;
    .embed-responsive {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      pointer-events: none;
      overflow: hidden;

      embed {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
